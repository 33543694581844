import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import AnimationRevealPage from '../components/AnimationRevealPage';
import Cdi from '../components/Cdi';
import Sports from '../components/Sports';
import Pedagogie from '../components/Pedagogie';
import Langues from '../components/Langues';

export default function VieScolaireEtPedagogique() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collègé Jules Verne - Vie scolaire et pédagogique"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <Pedagogie />
        <Cdi />
        {/* <LaCantine /> */}
        <Sports />
        <Langues/>
      </Layout>
    </AnimationRevealPage>
  );
}
