import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Cdi() {
  return (
    <div className="p-5 mx-auto sm:p-10 md:p-16 bg-coolGray-100 text-gray-800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-center">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
            <span class="relative inline-block text-blue-900">Le CDI</span>
          </h2>
          {/* <p class="text-base text-gray-700 md:text-lg">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            rem aperiam, eaque ipsa quae.
          </p> */}
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-3xl mx-auto overflow-hidden rounded">
        <StaticImage
          src="../images/cdi-2.webp"
          alt=""
          className="w-full h-60 sm:h-96 bg-coolGray-500"
        />

        <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md bg-white  shadow-lg z-10">
          <div className="text-coolGray-800">
            <ul className="list-disc space-y-2">
              <span className="font-medium text-blue-900">
                Le Centre de Documentation et d’Information (C.D.I)
              </span>
              <li>
                Lieu agréable et propice à l’apprentissage et à la lecture, le CDI est l’endroit
                idéal pour enrichir la culture des élèves et s'ouvrir au monde.
              </li>
              <li>
                Le Collège Français de Kairouan (CFK) dispose d’un centre d’information et de
                documentation (CDI) moderne, disposant de multiples ressources pédagogiques
                physiques et numériques.
              </li>
              <li>
                C’est un espace d’accueil pour tous les élèves et le personnel de l’établissement et
                un lieu aux multiples facettes qui remplit plusieurs fonctions essentielles pour les
                élèves :
                <br />
                <br />
                <span className="font-medium">1-</span>
                <span className="font-medium text-blue-900">Lieu de lecture</span> <br /> Comme une
                bibliothèque, le CDI propose aux élèves de nombreux et diverses ressources via
                différents supports papier :manuels scolaires, dictionnaires, encyclopédies, romans
                (classiques, littérature de jeunesse, en langues étrangères…), livres documentaires,
                pièces de théâtre, recueils de poésie, contes, bandes dessinées, magazines, et
                numériques (CD-ROM, DVD, Internetconformes à la liste officielles des oeuvres
                arrêtée par le MEN (Minsitére de l’education Nationale Français) qu’ils peuvent lire
                sur place ou emprunter.
                <br />
                <br />
                <span className="font-medium">2-</span>
                <span className="font-medium text-blue-900"> Espace de Travail</span>
                <br /> Le CDI accueille les élèves pendant les heures de permanence, le temps de
                pause méridienne , la récréation ou l’étude.
                <br /> Le professeur-documentaliste accueille, accompagne et guide les élèves dans
                leurs travaux (devoirs, recherches). <br />
                Les élèves peuvent également venir au CDI dans le cadre de séances pédagogiques
                menées en collaboration avec un enseignant sur des sujets variés. Les élèves sont
                alors formés aux principes de la « Recherche Documentaire ». Ils apprennent à
                connaître et à maîtriser les différents outils documentaires, l’information
                (recherches dans les livres et sur l’Internet), à exploiter et restituer cette
                information (prise de notes, synthèse, réalisation de dossiers, d’exposés…) Ils
                acquièrent ainsi des méthodes de travail afin de favoriser leur autonomie et de
                développer leur esprit critique, ce qui leur permettra d’appréhender l’offre
                d’information dans notre société et d’en tirer de manière optimale l’intérêt requis
                .
                <br />
                <br />
                <span className="font-medium">3-</span>
                <span className="font-medium text-blue-900"> Espace d’ouverture</span>
                <br /> De nombreuses manifestations culturelles vont jalonner l’année au CDI :
                semaine de la presse, de la poésie, expositions de travaux des élèves, concours,
                présentation d’ouvrages autour d’un thème…
                <br /> Les élèves peuvent ainsi satisfaire pleinement leur curiosité personnelle. Le
                C.D.I. est donc un lieu d’accès à des ressources diverses et variées, nécessaires à
                la réussite scolaire de l’élève, mais également à la formation du futur citoyen.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
