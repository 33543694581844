import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Langues() {
  return (
    <div className="p-5 mx-auto sm:p-10 md:p-16 bg-coolGray-100 text-gray-800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-center">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
            <span class="relative inline-block text-blue-900">Apprentissage des langues</span>
          </h2>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-3xl mx-auto overflow-hidden rounded">
        <StaticImage
          src="../images/stat-kairouan-school.webp"
          alt=""
          className="w-full h-60 sm:h-96 bg-coolGray-500"
        />

        <div className="p-6 pb-12 m-4 mx-auto space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md bg-white  shadow-lg z-10">
          <div className="text-coolGray-800">
            <ul className="list-disc space-y-2">
              La politique de l’enseignement des langues dans notre établissement répond aux
              exigences du plan stratégique de l’AEFE et vise à placer l’élève dans un parcours de
              maîtrise d’au moins trois langues étrangères et une maîtrise parfaite de la langue
              française.
              <h3 className="font-medium text-blue-900 mt-2 mb-2">
                La langue française langue d’enseignement et de communication principale.
              </h3>
              <li>
                La maîtrise de la langue française à l’oral comme à l’écrit est une obligation
                principale pour accéder aux savoirs et maîtriser les compétences nécessaires du
                programme. Ainsi tous les élèves admis au sein de notre établissement devront
                attester progressivement d’une maîtrise parfaite de cette langue. Dès l’inscription,
                un test écrit suivi d’un entretien oral permettront d’évaluer le niveau d’admission.
                Des actions régulières de consolidation et de perfectionnement permettent de relever
                ce niveau pour que chaque élève suive avec succès ses différents cours et qu’il
                puisse s’exprimer et communiquer en français, première langue de confort au collège.
                <br />
                Cependant le français sera également un outil de communication officielle et
                d’ouverture sur le monde. Plusieurs actions seront menées au sein de l’établissement
                pour permettre aux élèves d’échanger, de se cultiver et de s’exposer de manière
                fréquente à la langue.
              </li>
              <h3 className="font-medium text-blue-900 mt-2 mb-2">
                L’arabe la langue maternelle identitaire.
              </h3>
              <li>
                La langue arabe, langue de la Tunisie et langue maternelle de nos élèves jouit d’un
                statut particulier et extrêmement important pour le développement de leur
                personnalité équilibrée et fière de ses origines et de son appartenance.
              </li>
              <li>
                Cette langue, considérée comme langue du pays hôte par l’AEFE, est ainsi la pierre
                angulaire du parcours des langues de chaque élève et de son identité
                multilinguistique singulière.
              </li>
              <h3 className="font-medium text-blue-900 mt-2 mb-2">L’anglais et l’allemand.</h3>
              <li>
                «Les parcours PARLE (parcours adaptés et renforcés de langues étrangères) conçus par
                l’AEFE ont pour objectifs de conforter qualitativement la formation linguistique des
                élèves, de renforcer la maîtrise d’une langue vivante étrangère autre que la langue
                du pays hôte.»
              </li>
              <li>
                Le parcours plurilingue est progressif dès la sixième, par l’apprentissage de
                l’Anglais.
              </li>
              <li>
                L’apprentissage de l’anglais, en plus du français et de l’arabe est d’une importance
                capitale dans le monde d’aujourd’hui. Le collège français de Kairouan offre un
                enseignement renforcé dans cette langue, en mettant l’accent sur la certification.
                En effet, grâce au partenariat que nous avons développé avec un centre de formation
                en langue anglaise basé au Royaume-Unis et accrédité par le British Council, le
                collège français de Kairouan accompagne les élèves à passer des certifications en
                langue anglaise reconnues à l’international.
              </li>
              <br />
              Lors de nos échanges et discussions avec les familles , nous avons noté un intérêt
              particulier pour la langue allemande pour l’inclure dans le parcours des élèves de
              notre collège . A partir de la 5ème, les élèves pourront apprendre l’allemand et
              passer des certifications reconnues à l’international.
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
